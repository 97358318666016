import React, { useState } from 'react';

export default function AddPost() {
    const formStyle = {
        border: "1px solid black",
        padding: "0.5em 0.5em 0.5em",
        borderRadius: "1em",
        maxWidth: "55em",
        margin: "1em auto",
        position: "fixed",
        display: "block",
        backgroundColor: "honeydew",
        zIndex: 5000
    };

    const divStyle = {
        margin: "auto auto"
    }

    const [name, setName] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestBody = {
            username: name,
            title: title,
            content: content
        };
        fetch("https://cf2021general.leong.workers.dev/api", {
            method: "POST",
            headers: { "Content-Type" : "application/json" },
            body: JSON.stringify(requestBody)
        }).catch(err => console.log(err));

        window.location.reload(true);
    };

    return (
        <form onSubmit={handleSubmit} className="fixed-bottom" style={formStyle}>
            <div className="form-group" style={{...divStyle, "maxWidth": "50%"}}>
                <label>Name:</label>
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text" className="form-control" id="name" />
            </div>
            <div className="form-group" style={{...divStyle, "maxWidth": "50%"}}>
                <label>Title:</label>
                <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text" className="form-control" id="title" />
            </div>
            <div className="form-group" style={{...divStyle, "maxWidth": "95%"}}>
                <label>Content:</label>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="form-control" rows="3" id="content" />
            </div>
            <button type="submit" className="btn btn-primary" style={{margin: "0.5em"}}>
                Post
            </button>
        </form>
    );
}
