import React, { useEffect, useState } from 'react';
import Post from './Post';

export default function Posts() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        fetch("https://cf2021general.leong.workers.dev/api")
            .then(resp => resp.json())
            .then(data => setPosts(data))
            .catch(err => console.log(err));
    }, []);

    const divStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "50em",
        margin: "0 auto",
        paddingBottom: "20em"
    };

    return (
        <div style={divStyle}> { posts.map((post, i) =>
            <Post key={i} {...post}/>
        ) } </div>
    );
}
