import React from 'react';

export default function Post(props) {
    const { title, username, content } = props;

    const divStyle = {
        border: "1px solid black",
        padding: "0.5em 0.5em 0.5em",
        margin: "1em 1em 1em 1em",
        borderRadius: "1em",
        flex: 1
    };

    return (
        <div style={divStyle}>
            <h3>{title}</h3>
            <h4>{username}</h4>
            <p>{content}</p>
        </div>
    );
}
